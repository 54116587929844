import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ApiUtilLocal from '@/api/api.util.local'
const mode = 'DEV'
let vm
let vueStore

const ApiUtil = {
  init (vue) {
    Vue.use(VueAxios, axios)
    vm = vue
    vueStore = vue.$store
  },

  //조회
  query (resource, params = {}, isShowLoadingBar = true) {
    this.refineParams(params)

    if(isShowLoadingBar){
      vm.$store.dispatch('LOADING', true)
    }

    // 배열 params을 get방식에 맞게 변환
    let paramsSerializer = function(paramObj) {
      const params = new URLSearchParams()
      for (const key in paramObj) {
        params.append(key, paramObj[key])
      }
      return params.toString()
    }
    if (Vue == undefined || Vue.axios == undefined) {
      return axios.get(resource, { params, paramsSerializer })
      .then(respondsData=>{return this.checkRequiredReLogin(respondsData)})
      .finally(() => {
        if (isShowLoadingBar) {vm.$store.dispatch('LOADING', false)}
      })
    } else {
      return Vue.axios.get(resource, { params,paramsSerializer })
      .then(respondsData=>{ return this.checkRequiredReLogin(respondsData)})
      .finally(() => {
        if (isShowLoadingBar) {vm.$store.dispatch('LOADING', false)}
      })
    }

  },
  //추가
  post (resource, params = {}, isShowLoadingBar = true) {
    this.refineParams(params)

    if(isShowLoadingBar){
      vm.$store.dispatch('LOADING', true)
    }

    if(mode === 'LOCAL') {
      return ApiUtilLocal.post(`${resource}`, params)
    }

    if (Vue == undefined || Vue.axios == undefined) {
      return axios.post(`${resource}`, params)
      .then(respondsData=>{return this.checkRequiredReLogin(respondsData)})
      .finally(() => {
        if (isShowLoadingBar) {vm.$store.dispatch('LOADING', false)}
      })
    } else {
      return Vue.axios.post(`${resource}`, params)
      .then(respondsData=>{return this.checkRequiredReLogin(respondsData)})
      .finally(() => {
        if (isShowLoadingBar) {vm.$store.dispatch('LOADING', false)}
      })
    }
  },

  // 전체 업데이트
  put(resource, params = {}, isShowLoadingBar = true) {
    this.refineParams(params)

    if(isShowLoadingBar){
      vm.$store.dispatch('LOADING', true)
    }

    if (Vue == undefined || Vue.axios == undefined) {
      return axios.put(`${resource}`, params)
      .then(respondsData=>{return this.checkRequiredReLogin(respondsData)})
      .finally(() => {
        if (isShowLoadingBar) {vm.$store.dispatch('LOADING', false)}
      })
    } else {
      return Vue.axios.put(`${resource}`, params)
      .then(respondsData=>{return this.checkRequiredReLogin(respondsData)})
      .finally(() => {
        if (isShowLoadingBar) {vm.$store.dispatch('LOADING', false)}
      })
    }
  },

  // 부분 업데이트
  patch (resource, params = {}, isShowLoadingBar = true) {
    this.refineParams(params)

    if(isShowLoadingBar){
      vm.$store.dispatch('LOADING', true)
    }

    if (Vue == undefined || Vue.axios == undefined) {
      return axios.patch(`${resource}`, params)
      .then(respondsData=>{return this.checkRequiredReLogin(respondsData)})
      .finally(() => {
        if (isShowLoadingBar) {vm.$store.dispatch('LOADING', false)}
      })
    } else {
      return Vue.axios.patch(`${resource}`, params)
      .then(respondsData=>{return this.checkRequiredReLogin(respondsData)})
      .finally(() => {
        if (isShowLoadingBar) {vm.$store.dispatch('LOADING', false)}
      })
    }
  },

  //삭제
  delete(resource, params = {}, isShowLoadingBar = true) {
    this.refineParams(params)

    if(isShowLoadingBar){
      vm.$store.dispatch('LOADING', true)
    }

    if (Vue == undefined || Vue.axios == undefined) {
      return axios.delete(`${resource}`, {data : params})
      .then(respondsData=>{return this.checkRequiredReLogin(respondsData)})
      .finally(() => {
        if (isShowLoadingBar) {vm.$store.dispatch('LOADING', false)}
      })
    } else {
      return Vue.axios.delete(`${resource}`, {data : params})
      .then(respondsData=>{return this.checkRequiredReLogin(respondsData)})
      .finally(() => {
        if (isShowLoadingBar) {vm.$store.dispatch('LOADING', false)}
      })
    }
  },

  //파일다운로드
  fileDownload (resource, params = {}, isShowLoadingBar = true) {
    this.refineParams(params)

    let instances
    if(Vue == undefined || Vue.axios == undefined) instances = axios
    else instances = Vue.axios

    return instances.get(resource, {
      params,
      headers : { loginUserToken : params.loginUserToken },
      responseType : 'blob'
    })
    .finally(() => {
      if (isShowLoadingBar) {vueStore.dispatch('LOADING', false)}
    })
  },
  postIncludeUploadFile (resource, formData) {
    return Vue.axios.post(`${resource}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
  },

  get (resource, slug = '') {
    let url = `${resource}/${slug}`

    if (slug == '') {
      url = `${resource}`
    }

    return Vue.axios.get(url).catch(error => {
      throw new Error(`[ApiService]\nerror: ${error} \nurl: ${url}`)
    })
  },

  refineParams(params = {}){
    params.loginUserToken = params.loginUserToken ?? vueStore.getters.loginUserToken
  },

  checkRequiredReLogin(respondsData){
    try {
      if(respondsData.data.resultStatus.messageCode === '4154'){
        kendo.alert(respondsData.data.resultStatus.messageText).bind('close', function() {
          vm.$store.dispatch('LOGOUT').then(() => {
            window.location.href = `/login`
          })
        })
      }else {
        return respondsData
      }
    }catch (e){
      return respondsData
    }
  }
}

export default ApiUtil
