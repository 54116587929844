const Constant = {
    key: {
        SESSION_LAST_ROUTE_NAME: 'LAST_ROUTE_NAME',
        SESSION_LAST_ROUTE_PARAM: 'LAST_ROUTE_PARAMS',
        SESSION_LOGIN_USER_TOKEN: 'lutk',
        SESSION_LOGIN_USER_DATA: 'loginUserData',
    },
    /**
     * FMS 영역
     */
    fms: {
        wmGbn: {
            WEB_CD: '01',       // 웹
            MOBILE_CD: '02',    // 모바일
        }
    },
    /**
     * EFS 영역
     */
    efs: {
        auth : {
            LOGISTICS_MANAGER : '05',
            OPERATION_MANAGER : '10',
            GENERAL_MANAGER : '15',
            CLIENT_USER : '25',
            SUPPLIER : '35',
            TEMPORARY_USER : '45',
            SYSTEM_ADMINISTRATOR : '99999',
        },
        cmpyGbn : {
            ETNERS_COMPANY : '01',
            CLIENT_COMPANY : '02',
            DELIVERY_COMPANY : '03',
            QUICK_COMPANY : '04',
            PURCHASING_COMPANY : '05',
        },

    },
}

export default Constant
