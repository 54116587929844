import Vue from 'vue'
import VueRouter from 'vue-router'
import { store } from '../store/index'
import Constant from '@/common/constant'

Vue.use(VueRouter)

// 최상위 메뉴(Depth1)를 클릭하는 경우
const routerNextDepth2 = () => (to, from, next) => {

  let menuData = store.getters.menuData
  for(let depth1Menu of menuData.depth1MenuList){
    if(depth1Menu.urlLink === to.path){
      // 접근가능한 하위 메뉴(depth2)중 가장 첫번째로 이동
      let depth2MenuData = menuData.depth2MenuObj[depth1Menu.menuId]
      return next(depth2MenuData[0].urlLink)
    }
  }
  return next('/notFound')
}

const logout = () => (to, from, next) => {
  store.dispatch('LOGOUT').then(() => {
    window.location.href = `/landing`
  })
}

export const router = new VueRouter({
    mode: 'history',
    routes: [{
            path: '/',
            redirect: '/landing'
        },
        // 로그인 페이지
        {
            path: '/login',
            name: 'Login',
            component: () =>
              import ('@/views/login/Login.vue'),
            meta: {
                layout: 'LoginLayout'
            },
            props: true
          },
          // 로그인 페이지
          {
            path: '/logout',
            name: 'Logout',
            meta: {
              layout: 'EmptyLayout'
            },
            beforeEnter : logout(),
          },
          // 로그인 페이지
          {
            path: '/join',
            name: 'Join',
            component: () =>
              import ('@/views/login/Join.vue'),
            meta: {
              layout: 'EmptyLayout'
            },
            props: true
          },
          // 로그인 페이지
          {
            path: '/enter',
            name: 'SelectCompany',
            component: () =>
              import ('@/views/login/SelectCompany.vue'),
            meta: {
              layout: 'EmptyLayout'
            },
            props: true
          },
        {
            path: '/findAccount',
            name: 'FindAccount',
            component: () =>
              import ('@/views/login/account/FindAccount.vue'),
            meta: {
                layout: 'LoginLayout',
            },
            props: true,
        },
        {
          path: '/change-password',
          name: 'ChangePassword',
          component: () =>
            import ('@/views/login/ChangePassword.vue'),
          meta: {
            layout: 'EmptyLayout',
          },
        },
        /**
         * 마이페이지
         */
        {
          path: '/mypage',
          name: 'MyPageDetail',
          component: () =>
            import ('@/views/mypage/MyPageDetail.vue'),
        },
        /**
         * 마이페이지 수정
         */
        {
          path: '/mypage/save',
          name: 'MyPageEdit',
          component: () =>
            import ('@/views/mypage/MyPageSave.vue'),
        },
        /**
         * 공지사항목록
         */
        {
          path: '/board',
          name: 'BoardList',
          component: () =>
            import ('@/views/board/BoardList.vue'),
        },
      /**
       * 공지사항 등록
       */
      {
        path: '/board/save',
        name: 'BoardNew',
        component: () =>
          import ('@/views/board/BoardSave.vue'),
      },
      /**
       * 공지사항 수정
       */
      {
        path: '/board/save/:boardId',
        name: 'BoardEdit',
        component: () =>
          import ('@/views/board/BoardSave.vue'),
      },
      /**
       * 공지사항 > 공지사항 상세
       */
      {
        path: '/board/:boardId',
        name: 'BoardDetail',
        component: () =>
          import ('@/views/board/BoardDetail.vue'),
      },
        /**
         * 대시보드
         */
        {
          path: '/landing',
          name: 'Landing',
          meta: {
            layout: () => import('../layouts/EmptyLayout')
          },
          component: () => import ('../landing/layout/LandingLayout.vue'),
            children : [
              {
                path: "/landing",
                component: () => import ('../landing/view/Landing.vue'),
                name: "Landing",
                props : true,
                meta: {
                  layout: 'EmptyLayout'
                },
              },
              {
                path: "/landing/pricing",
                component: () => import ('../landing/view/Pricing.vue'),
                name: "Pricing",
                meta: {
                  layout: 'EmptyLayout'
                },
              },
              {
                path: "/landing/requestService",
                component: () => import ('../landing/view/RequestService.vue'),
                name: "RequestService",
                meta: {
                  layout: 'EmptyLayout'
                },
              },
              {
                path: "/landing/ApplicationCompleted",
                component: () => import ('../landing/view/ApplicationCompleted.vue'),
                name: "ApplicationCompleted",
                meta: {
                  layout: 'EmptyLayout'
                },
              },
              {
                path: "/landing/mypage",
                component: () => import ('../landing/view/mypage/Mypage.vue'),
                name: "PortalMypage",
                meta: {
                  layout: 'EmptyLayout'
                },    
              }
            ]
        },
        /**
         * 대시보드
         */
        {
            path: '/main',
            name: 'DashBoard',
            component: () =>
              import ('../views/dashboard/DashBoard.vue'),
        },
        /**
         * 운영메뉴
         */
        {
            path: '/manage',
            beforeEnter: routerNextDepth2(),
            // redirect: '/goods/manage',
        },
        /**
         * 운영메뉴 > 회사관리
         */
        {
            path: '/manage/company',
            name: 'CompanyManageList',
            component: () =>
              import ('../views/manage/company/CompanyManageList')
        },
        /**
         * 운영메뉴 > 회사관리 > 회사등록
         */
        {
          path: '/manage/company/save',
          name: 'CompanyManageNew',
          component: () =>
            import ('../views/manage/company/CompanyManageSave')
        },
        /**
         * 운영메뉴 > 회사관리 > 회사상세
         */
        {
            path: '/manage/company/:cmpyCd',
            name: 'CompanyManageDetail',
            component: () =>
              import ('../views/manage/company/CompanyManageDetail')
        },
        /**
         * 운영메뉴 > 회사관리 > 회사수정
         */
        {
            path: '/manage/company/save/:cmpyCd',
            name: 'CompanyManageEdit',
            component: () =>
              import ('../views/manage/company/CompanyManageSave')
        },
        /**
        //  * 운영메뉴 > 로케이션관리
        //  */
        {
          path: '/manage/loca',
          name: 'LocationManageList',
          component: () =>
            import ('../views/manage/loca/LocationManageList')
        },
        /**
         * 운영메뉴 > 로케이션관리 > 로케이션등록
         */
        {
          path: '/manage/loca/save',
          name: 'LocationManageNew',
          component: () =>
            import ('../views/manage/loca/LocationManageSave')
        },
        /**
         * 운영메뉴 > 로케이션관리 > 로케이션상세
         */
        {
          path: '/manage/loca/:cmpyCd/:locaCd',
          name: 'LocationManageDetail',
          component: () =>
            import ('../views/manage/loca/LocationManageDetail')
        },
        /**
         * 운영메뉴 > 로케이션관리 > 로케이션수정
         */
        {
          path: '/manage/loca/save/:cmpyCd/:locaCd',
          name: 'LocationManageEdit',
          component: () =>
            import ('../views/manage/loca/LocationManageSave')
        },
        /**
         * 운영메뉴 > 섹터관리
         */
        {
          path: '/manage/sect',
          name: 'SectorManageList',
          component: () =>
            import ('../views/manage/sect/SectorManageList')
        },
        /**
         * 운영메뉴 > 섹터관리 > 섹터등록
         */
        {
          path: '/manage/sect/save',
          name: 'SectorManageNew',
          component: () =>
            import ('../views/manage/sect/SectorManageSave')
        },
        /**
         * 운영메뉴 > 섹터관리 > 섹터상세
         */
        {
          path: '/manage/sect/:cmpyCd/:locaCd/:whCd/:sectCd',
          name: 'SectorManageDetail',
          component: () =>
            import ('../views/manage/sect/SectorManageDetail')
        },
        /**
         * 운영메뉴 > 섹터관리 > 섹터수정
         */
        {
          path: '/manage/sect/save/:cmpyCd/:locaCd/:whCd/:sectCd',
          name: 'SectorManageEdit',
          component: () =>
            import ('../views/manage/sect/SectorManageSave')
        },
        /**
         *  NotFound
         */
        {
          path: '/notFound',
          name: 'NotFound',
          meta: {
            layout: 'EmptyLayout'
          },
          component: () =>
            import ('../components/NotFound')
        },
        /**
         * 운영메뉴 > 회원관리
         */
        {
          path: '/manage/user',
          name: 'UserManageList',
          component: () =>
            import ('../views/manage/user/UserManageList.vue')
        },
      /**
       * 운영메뉴 > 회원관리 > 회원등록
       */
      {
        path: '/manage/user/save',
        name: 'UserManageNew',
        component: () =>
          import ('../views/manage/user/UserManageSave.vue')
      },
      /**
       * 운영메뉴 > 회원관리 > 회원상세
       */
      {
        path: '/manage/user/:unqUserId',
        name: 'UserManageDetail',
        component: () =>
          import ('../views/manage/user/UserManageDetail.vue')
      },
      /**
       * 운영메뉴 > 회원관리 > 회원수정
       */
      {
        path: '/manage/user/save/:unqUserId',
        name: 'UserManageEdit',
        component: () =>
          import ('../views/manage/user/UserManageSave.vue')
      },
      /**
       * 운영메뉴 > 발주관리 > 발주캘린더
       */
      {
        path: '/manage/ord',
        name: 'OrdManageCalendar',
        component: () =>
          import ('../views/manage/ord/OrdManageCalendar.vue')
      },
      /**
       * 운영메뉴 > 발주관리 > 발주목록
       */
      {
        path: '/manage/ord/list',
        name: 'OrdManageList',
        component: () =>
          import ('../views/manage/ord/OrdManageList.vue')
      },
      /**
       * 운영메뉴 > 발주관리 > 발주등록
       */
      {
        path: '/manage/ord/save',
        name: 'OrdManageNew',
        component: () =>
          import ('../views/manage/ord/OrdManageSave.vue')
      },
      /**
       * 운영메뉴 > 발주관리 > 발주수정
       */
      {
        path: '/manage/ord/save/:ordNo',
        name: 'OrdManageEdit',
        component: () =>
          import ('../views/manage/ord/OrdManageSave.vue')
      },
      /**
       * 운영메뉴 > 발주관리 > 발주상세
       */
      {
        path: '/manage/ord/:ordNo',
        name: 'OrdManageDetail',
        component: () =>
          import ('../views/manage/ord/OrdManageDetail.vue')
      },
      /**
       * 운영메뉴 > 서비스신청관리
       */
      {
        path: '/manage/svc',
        name: 'ServiceRequestManageList',
        component: () =>
          import ('../views/manage/svc/ServiceRequestManageList.vue')
      },
      /**
       * 운영메뉴 > 서비스신청관리 > 수기신청등록
       */
      {
        path: '/manage/svc/save',
        name: 'ServiceRequestManageNew',
        component: () =>
          import ('../views/manage/svc/ServiceRequestManageSave.vue')
      },
      /**
       * 운영메뉴 > 서비스신청상세 > 수정
       */
      {
        path: '/manage/svc/save/:svcReqNo',
        name: 'ServiceRequestManageEdit',
        component: () =>
          import ('../views/manage/svc/ServiceRequestManageSave.vue')
      },
      /**
       * 운영메뉴 > 서비스신청상세
       */
      {
        path: '/manage/svc/:svcReqNo',
        name: 'ServiceRequestManageDetail',
        component: () =>
          import ('../views/manage/svc/ServiceRequestManageDetail.vue')
      },
      /**
       * 운영메뉴 > 서비스신청관리
       */
      {
        path: '/manage/payment',
        name: 'ServicePaymentManageList',
        component: () =>
          import ('../views/manage/payment/ServicePaymentManageList.vue')
      },
      /**
       * 모듈메뉴
       */
      {
        path: '/module',
        beforeEnter: routerNextDepth2(),
      },
      /**
       * 모듈메뉴 > 코드관리
       */
      {
        path: '/module/code',
        name: 'CodeManageList',
        component: () =>
          import ('../views/module/code/CodeManageList.vue')
      },
      /**
       * 모듈메뉴 > 물류템플릿관리
       */
      {
        path: '/module/logi',
        name: 'LogisticsTemplateManageList',
        component: () =>
          import ('../views/module/logi/LogisticsTemplateManageList.vue')
      },
      /**
       * 모듈메뉴 > 물류템플릿관리 > 등록
       */
      {
        path: '/module/logi/save',
        name: 'LogisticsTemplateManageNew',
        component: () =>
          import ('../views/module/logi/LogisticsTemplateManageSave.vue')
      },
      /**
       * 모듈메뉴 > 물류템플릿관리 > 수정
       */
      {
        path: '/module/logi/save/:cmpyCd/:logiDepth1',
        name: 'LogisticsTemplateManageEdit',
        component: () =>
          import ('../views/module/logi/LogisticsTemplateManageSave.vue')
      },
      /**
       * 모듈메뉴 > 물류템플릿관리 > 상세
       */
      {
        path: '/module/logi/:cmpyCd/:logiDepth1',
        name: 'LogisticsTemplateManageDetail',
        component: () =>
          import ('../views/module/logi/LogisticsTemplateManageDetail.vue')
      },
      /**
       * 생산메뉴
       */
      {
        path: '/prod',
        beforeEnter: routerNextDepth2(),
      },
      /**
       * 생산메뉴 > 생산성현황
       */
      {
        path: '/prod/stat',
        name: 'ProductStatusList',
        component: () =>
          import ('../views/prod/stat/ProductStatusList.vue')
      },
      /**
       * 생산메뉴 > 원가산출
       */
      {
        path: '/prod/cost',
        name: 'CostCalculationList',
        component: () =>
          import ('../views/prod/cost/CostCalculationList.vue')
      },
      /**
       * 생산메뉴 >
       */
      {
        path: '/prod/prcs',
        name: 'ProcessManageList',
        component: () =>
          import ('../views/prod/prcs/ProcessManageList.vue')
      },
      /**
       * 리포트
       */
      {
        path: '/report',
        name: 'ReportItemList',
        beforeEnter: routerNextDepth2(),
        component: () =>
          import ('../views/report/item/ReportItemList.vue')
      },
      /**
       * 리포트 > 품목별 통계
       */
      {
        path: '/report/item',
        name: 'ReportItemList',
        component: () =>
          import ('../views/report/item/ReportItemList.vue')
      },
      /**
       * 리포트 > 세트별 통계
       */
      {
        path: '/report/set',
        name: 'ReportSetList',
        component: () =>
          import ('../views/report/set/ReportSetList.vue')
      },
      /**
       * 리포트 > 세트별 통계
       */
      {
        path: '/report/loca',
        name: 'ReportLocationList',
        component: () =>
          import ('../views/report/loca/ReportLocationList.vue')
      },
      /**
       * 리포트 > 섹터별 통계
       */
      {
        path: '/report/sect',
        name: 'ReportSectorList',
        component: () =>
          import ('../views/report/sect/ReportSectorList.vue')
      },
    ],
    // 스크롤 동작 : 브라우저가 지원하는 경우에만 작동
    // ex> https://router.vuejs.org/guide/advanced/scroll-behavior.html
    // router 호출시 scroll 위치 0,0
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

// 네비게이션 가드 (전역가드)
router.beforeEach(function(to, from, next) {

  store.dispatch('API_COUNT', 0)
  store.dispatch('SET_IS_SHOW_LOGIN_POPUP', false)

  // 끝에 /가 있는경우 제거하여 redirect
  let reg = /\/+$/
  if(reg.exec(to.path)){
    let redirect = to.path.replace(reg, '')
    return next(redirect)
  }

  const loginUserToken = store.state.loginStore.lutk
  // 로그인한 경우 들어오면 안되는 화면
  let onlyNewUserUrl = ['/login','/join']
  let mode = location.hostname.includes('dev') || location.hostname.includes('localhost') ? 'dev-' : ''
  if(onlyNewUserUrl.includes(to.path) && !!loginUserToken){
    kendo.alert('로그인되어 있는 상태로 접근할 수 없습니다.').bind('close', function() {
      window.location.href=`https://${mode === 'dev-' ? 'dev-efs.' : ''}etnersefs.com`
    })
    return
  }
  // 접속 가능여부 체크 없이 pass 처리
  const allpassUrl = [
    '/login',
    '/landing',
    '/findAccount',
    '/notFound',
    '/logout'
  ]
  if(store.state.loginStore.saasMode){
    let forSaasUrl = [
      '/landing/pricing',
      '/landing/requestService',
      '/landing/ApplicationCompleted',
      '/landing/mypage',
      '/join']
    allpassUrl.push(...forSaasUrl)
  }

  const allpassName = ['Login','FindAccount']
  if (allpassUrl.includes(to.path) || allpassName.includes(to.name)) {
    return next()
  }

  // 토큰 정보 없는 경우
  if (!loginUserToken) {
    // 로그인 화면 접속이 아닌 경우 로그인 화면으로 이동시킴
    if (to.path !== '/' && to.path !== '/login') {
      alert('로그인이 필요합니다.')
      return next('/login')
    } else {
      return next()
    }
  }

  // 접속자 메뉴리스트
  const originalMenuData = store.getters.menuMaster
    let passUrlPath =
      [ '/',
        '/main',
        '/mypage',
        '/mypage/save',
        '/enter'
      ]
    let passUrlName =
      [ 'BoardList','BoardDetail','BoardNew','BoardEdit','ChangePassword']

    const boolMenuAuth = passUrlPath.includes(to.path) || passUrlName.includes(to.name)
      || (originalMenuData?.some(menu => { return menu.urlLink === to.path
        || (menu.superMenuId && to.path.startsWith(menu.urlLink) && to.name?.includes('Detail'))
        || (menu.superMenuId && to.path.startsWith(menu.urlLink) && to.name?.includes('New'))
        || (menu.superMenuId && to.path.startsWith(menu.urlLink) && to.name?.includes('Edit'))
        || (menu.superMenuId && to.path.startsWith(menu.urlLink) && to.name?.includes('List'))
        || (menu.superMenuId && to.path.startsWith(menu.urlLink) && to.name?.includes('Calendar'))
    }))

    /** 접속 권한이 있는 메뉴 진입인 경우 */
    if (boolMenuAuth) {
      /**
       * 메뉴의 권한은 있지만 특정 페이지의 권한은 없는 케이스
       * */
      let noPassName
      switch (store.getters.loginUserData.authCd) {
        case Constant.efs.auth.OPERATION_MANAGER:  // 운관
          noPassName = ['BoardNew', 'BoardEdit']
          if (noPassName.includes(to.name)) {
            window.location.href = router.resolve({ name: "NotFound", }).href
          }
          break
      }
      return next()
    }else {
      window.location.href = router.resolve({ name: "NotFound", }).href
    }

})

router.afterEach((to, from) => {
    sessionStorage.setItem('LAST_ROUTE_NAME', from.name)
});
