import Vue from 'vue'
import App from './App.vue' //최초 진입점
import { router } from '@/routes' //vue-router
import { store } from '@/store' //vuex
import ApiUtil from './api/api.util' //Etners API Util
import VueI18n from 'vue-i18n'
import AsyncComputed from 'vue-async-computed'
import VCalendar from 'v-calendar';
import EtnersCommonLib from '@ebmp-fe-common-module/module/src/etners.lib'
Vue.prototype.EventBus = new Vue()

/* Kendo UI Style Import */
import '@progress/kendo-ui'

/* Kendo Button */
import { Button,
  ButtonGroup,
  ButtonGroupButton,
  ToolBar,
  ToolBarItem,
  ButtonsInstaller } from '@progress/kendo-buttons-vue-wrapper'

/* Kendo Grid */
import { Grid,
  GridInstaller } from '@progress/kendo-grid-vue-wrapper'

/* Kendo Scheduler */
import { Scheduler,
  SchedulerResource,
  SchedulerView,
  SchedulerInstaller } from '@progress/kendo-scheduler-vue-wrapper'

/* Kendo Window */
import { Window, WindowInstaller } from '@progress/kendo-window-vue-wrapper'

/* Kendo TreeView */
import { TreeView, TreeViewItem, TreeViewInstaller } from '@progress/kendo-treeview-vue-wrapper';

/* Kendo TreeList */
import { TreeList, TreeListInstaller } from '@progress/kendo-treelist-vue-wrapper'

/* Kendo Listbox */
import { ListBox, ListBoxInstaller } from '@progress/kendo-listbox-vue-wrapper'

import { Validator, ValidatorInstaller } from '@progress/kendo-validator-vue-wrapper'

/* Kendo Chart */
import { Chart,
  ChartSeriesItem,
  Sparkline,
  SparklineSeriesItem,
  StockChart,
  ChartInstaller } from '@progress/kendo-charts-vue-wrapper'

import { LinearGauge,
  LinearGaugePointer,
  RadialGauge,
  RadialGaugePointer,
  ArcGauge,
  ArcGaugeColor,
  GaugesInstaller} from '@progress/kendo-gauges-vue-wrapper'

import { Calendar,
  DateInput,
  DatePicker,
  DateRangePicker,
  DateTimePicker,
  MultiViewCalendar,
  TimePicker,
  DateinputsInstaller } from '@progress/kendo-dateinputs-vue-wrapper'

import {
  MaskedTextBox,
  NumericTextBox,
  ColorPicker,
  Slider,
  RangeSlider,
  Switch as KendoSwitch,
  InputsInstaller,
} from '@progress/kendo-inputs-vue-wrapper'


import { Upload, UploadInstaller } from '@progress/kendo-upload-vue-wrapper'

import { LayoutInstaller } from '@progress/kendo-layout-vue-wrapper'

/* Kendo Editor */
import { Editor, EditorTool, EditorInstaller } from '@progress/kendo-editor-vue-wrapper'

/* Kendo Dropdowns */
import {
  AutoComplete,
  ComboBox,
  DropDownList,
  MultiSelect,
  MultiColumnComboBox,
  MultiColumnComboBoxColumn,
  DropdownsInstaller
} from '@progress/kendo-dropdowns-vue-wrapper'

/* Kendo Barcode */
import {
  Barcode,
  QRCode,
  BarcodesInstaller } from '@progress/kendo-barcodes-vue-wrapper'

Vue.use(ButtonsInstaller)
Vue.use(GridInstaller)
Vue.use(SchedulerInstaller)
Vue.use(WindowInstaller)
Vue.use(DateinputsInstaller)
Vue.use(UploadInstaller)
Vue.use(TreeViewInstaller)
Vue.use(TreeListInstaller)
Vue.use(ListBoxInstaller)
Vue.use(ValidatorInstaller)
Vue.use(ChartInstaller)
Vue.use(GaugesInstaller)
Vue.use(InputsInstaller)
Vue.use(LayoutInstaller)
Vue.use(EditorInstaller)
Vue.use(DropdownsInstaller)
Vue.use(VueI18n)
Vue.use(AsyncComputed)
Vue.use(VCalendar)
Vue.use(EtnersCommonLib)

Vue.config.productionTip = false

new Vue({
  i18n      : new VueI18n({
    locale        : 'ko', // 기본언어는 ko로 유지하지만 브라우저 언어를 체크해서 변경
    fallbackLocale: 'ko',
    messages      : [],
  }),
  beforeCreate () {
    ApiUtil.init(this)
    // this.$store.dispatch('FETCH_URL')
    // TODO:
    /* const vm = this
    let loginUserData = JSON.parse(sessionStorage.getItem('loginUserData'))
    let param = {
      cmpyCd : loginUserData.cmpyCd,
      unqUserId: loginUserData.unqUserId,
      authCd: loginUserData.authCd,
      menuGbn: "02",
      wmGbn: Constant.fms.wmGbn.WEB_CD,
    }
    ApiUtil.query(`${ApiConfig.efsDomain}/efs/menu`,param).then(response => {
      sessionStorage.setItem('menuMaster', JSON.stringify(response.data.resultData))
      const localeArray = ['ko', 'en', 'zh', 'vn', 'ja']
      localeArray.forEach(function (locale) {
        const msg = response.data.resultData
        vm.$i18n.setLocaleMessage(locale, msg)
      })
    }) */
  },
  router,
  store,
  render    : h => h(App),
  components: {
    Validator,

    /* Button */
    Button,
    ButtonGroup,
    ButtonGroupButton,
    ToolBar,
    ToolBarItem,

    /* Grid */
    Grid,

    /* Scheduler */
    Scheduler,
    SchedulerResource,
    SchedulerView,

    /* Window */
    Window,

    /* Date-Picker */
    Calendar,
    DateInput,
    DatePicker,
    DateRangePicker,
    DateTimePicker,
    MultiViewCalendar,
    TimePicker,

    /* Upload */
    Upload,

    /* Inputs */
    MaskedTextBox,
    NumericTextBox,
    ColorPicker,
    Slider,
    RangeSlider,
    KendoSwitch,

    /* TreeView */
    TreeView,
    TreeViewItem,

    /* ListBox */
    ListBox,

    /* TreeList */
    TreeList,

    /* Chart */
    Chart,
    Sparkline,
    StockChart,
    ChartSeriesItem,
    SparklineSeriesItem,

    LinearGauge,
    LinearGaugePointer,
    RadialGauge,
    RadialGaugePointer,
    ArcGauge,
    ArcGaugeColor,

    /* Editor */
    Editor,
    EditorTool,

    /* Dropdowns */
    AutoComplete,
    ComboBox,
    DropDownList,
    MultiSelect,
    MultiColumnComboBox,
    MultiColumnComboBoxColumn,

    /* Barcode */
    Barcode,
    QRCode,
    BarcodesInstaller
  },
}).$mount('#app')

