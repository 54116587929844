import {
  fetchedConfirmId,
  fetchedFindId,
  fetchedLoginData,
  fetchedLoginUserData,
  fetchedMenuData,
  fetchedSendAuthNumber,
  fetchedUpdatePassword,
  fetchedUpdatePasswordByToken
} from '@/api/api.login'
import EtnersCommonUtil
  from '@ebmp-fe-common-module/module/src/common/etners.common.util'
// import JwtService from '@ebmp-fe-common-module/module/src/common/jwt.service';


const loginStoreModule = {
  state : {
    lutk: null,
    isFirstLogin: null,
    isAuthenticated: false,
    loginErrors: null,
    loginUserData : null,
    linkCmpyCd: null,
    linkLocaCd: null,
    menuMaster: null,
    loading: false,
    apiCount: false,
    saasMode : false,
    isShowLoginPopup : false,
  },
  getters  : {
    isShowLoginPopup (state) {
      return state.isShowLoginPopup
    },
    isAuthenticated (state) {
      return state.isAuthenticated
    },
    firstAceessInfoCmpyMng (state) {
      return state.loginUserData?.firstAceessInfoCmpyMng
    },
    loginUserToken (state) {
      return state.lutk
    },
    loading (state) {
      if(state.apiCount < 0){
        return false
      }
      return state.loading
    },
    apiCount (state) {
      return state.apiCount
    },
    loginUserData (state) {
      return state.loginUserData
    },
    linkCmpyCd (state) {
      return state.linkCmpyCd
    },
    linkLocaCd (state) {
      return state.linkLocaCd
    },
    linkLocaNm (state) {
      let linkCmpyCdLinkLocaList = state.loginUserData?.firstAceessInfoCmpyMng.find(item => item.mngCmpyCd === state.linkCmpyCd).locaList
      let locaNm = linkCmpyCdLinkLocaList.find(loca => loca.locaCd === state.linkLocaCd).locaNm
      return locaNm
    },
    linkLocaAddrData (state) {
      if(state.linkCmpyCd && state.linkLocaCd) {
        let linkCmpyCdLinkLocaList = state.loginUserData?.firstAceessInfoCmpyMng.find(item => item.mngCmpyCd === state.linkCmpyCd).locaList
        let { locaZipNo, locaAddr, locaAddrDtl } = linkCmpyCdLinkLocaList.find(loca => loca.locaCd === state.linkLocaCd)
        return {
          locaZipNo,
          locaAddr,
          locaAddrDtl
        }
      } else {
        return null
      }
    },
    menuMaster (state) {
      return state.menuMaster
    },
    menuData (state) {
      let menuMaster = state.menuMaster

      // depth1 메뉴 리스트 (중복 제거)
      let depth1MenuList = [...new Map(
        menuMaster?.filter(menuItem => {
          return EtnersCommonUtil.isEmpty(menuItem.superMenuId)
        })
        .map(filterdMenuItem => {
          return [filterdMenuItem.menuId, filterdMenuItem]
        })).values()]

      // depth2 메뉴 key object
      let depth2MenuObj = {}
      menuMaster?.filter(menuItem => {
        return EtnersCommonUtil.isNotEmpty(menuItem.superMenuId)
      })
      .forEach(filterdMenuItem => {

        if(depth2MenuObj[filterdMenuItem.superMenuId] === undefined) {
          depth2MenuObj[filterdMenuItem.superMenuId] = [filterdMenuItem]
        } else {
          depth2MenuObj[filterdMenuItem.superMenuId].push(filterdMenuItem)
        }
      })

      return {
        depth1MenuList,
        depth2MenuObj
      }
    },
  },
  mutations: {
    SET_LOGIN_DATA (state, loginData) {

      // console.log(`SET_LOGIN_DATA !!`)
      // 상태 저장(로그인 여부, loginUserData토큰, 최초로그인 여부)
      state.isAuthenticated = true
      state.lutk = loginData.loginUserToken
      state.isFirstLogin = loginData.firstLoginYn
      // sessionStorage 저장(토큰, 최초로그인 여부)
      sessionStorage.setItem('lutk', loginData.loginUserToken)
      sessionStorage.setItem('isFirstLogin', loginData.firstLoginYn)


      /* console.log(`SET_LOGIN_DATA !!`)
      state.isAuthenticated = true
      state.loginUserData = loginUserData
      const lutk = state.loginUserData.resultData.loginUserToken
      JwtService.saveToken(lutk)
      sessionStorage.setItem('userInfo',JSON.stringify(loginUserData.resultData))
      sessionStorage.setItem('isFirstLogin',loginUserData.resultData.firstLoginYn)
      state.isFirstLogin = loginUserData.resultData.firstLoginYn */
    },
    SET_LOGIN_USER_DATA (state, loginUserData) {

      // console.log(`SET_LOGIN_USER_DATA !!`)
      // 상태 저장(로그인 사용자 정보)
      state.loginUserData = loginUserData
      // sessionStorate 저장(로그인 사용자 정보)
      sessionStorage.setItem('loginUserData', JSON.stringify(loginUserData))
    },
    // 처리 권한 회사 저장
    SET_LINK_CMPY_CD (state, cmpyCd) {
      // console.log(`SET_LINK_CMPY_CD !!`)
      state.linkCmpyCd = cmpyCd
      state.linkLocaCd = state.loginUserData?.firstAceessInfoCmpyMng.find(item => item.mngCmpyCd === state.linkCmpyCd)?.locaList[0]?.locaCd
      sessionStorage.setItem('linkCmpyCd', cmpyCd)
    },
    // 처리 권한 로케이션 저장
    SET_LINK_LOCA_CD (state, locaCd) {
      // console.log(`SET_LINK_LOCA_CD !!`)
      state.linkLocaCd = locaCd
      sessionStorage.setItem('linkLocaCd', locaCd)
    },
    /* SET_FIRST_LOGIN_DATA (state, loginUserData) {
      console.log(`SET_FIRST_LOGIN_DATA !!`)
      state.isAuthenticated = false
      state.loginUserData = loginUserData
      const lutk = state.loginUserData.resultData.loginUserToken
      JwtService.saveToken(lutk)
      sessionStorage.setItem('userInfo',JSON.stringify(loginUserData.resultData))
      state.isFirstLogin= loginUserData.resultData.firstLoginYn
    }, */
    SET_LOGIN_ERROR (state, error) {
      // console.log(`SET_LOGIN_ERROR !!`)
      state.loginErrors = error

      kendo.alert(error)
    },
    SET_MENU_DATA (state, menuMaster) {
      // console.log(`SET_MENU_DATA !!`)
      state.menuMaster = menuMaster
    },
    SET_LOADING (state, isShow) {
      if(isShow) state.apiCount++
      else if(!isShow && state.apiCount > 0) state.apiCount--
      state.loading = state.apiCount !== 0;
    },
    SET_API_COUNT (state, cnt) {
      state.apiCount = cnt
    },
    REMOVE_LOGIN_DATA (state) {
      // console.log(`REMOVE_LOGIN_DATA !!`)
      state.lutk = null
      state.isFirstLogin = null
      state.isAuthenticated = false
      state.loginErrors = null
      state.loginUserData = null
      state.linkCmpyCd = null
      state.linkLocaCd = null
      state.menuMaster = null
      state.apiCount = 0
      state.isShowLoginPopup = false
    },
    SET_IS_SHOW_LOGIN_POPUP ( state, isShow){
      state.isShowLoginPopup = isShow
    }
  },
  actions  : {
    // 로그인
    FETCH_LOGIN (context, credentials) {
      return new Promise(resolve => {
        fetchedLoginData(credentials).then(response => {

          if (response.data.resultStatus.messageCode === '2000') {
            context.commit('SET_LOGIN_DATA', response.data.resultData)
            resolve(response.data)
          } else {
            context.commit('SET_LOGIN_ERROR', response.data.resultStatus.messageText);
          }
        }).catch(error => {
          console.log('==================== error ====================')
          console.log(error)
          context.commit('SET_LOGIN_ERROR', error)
        })
      })
    },
    // 로그인 사용자 정보
    FETCH_LOGIN_USER_DATA (context, credentials) {
      return new Promise(resolve => {
        fetchedLoginUserData(credentials).then(response => {

          if (response.data.resultStatus.messageCode === '2000') {
            // 로그인 사용자 정보 저장
            context.commit('SET_LOGIN_USER_DATA', response.data.resultData)
            // 처리 권한 회사 기본 저장
            context.commit('SET_LINK_CMPY_CD', response.data.resultData?.cmpyCd)
            // 처리 권한 로케이션 저장
            //test
            // context.commit('SET_LINK_LOCA_CD', '0001')
            //real
            // context.commit('SET_LINK_LOCA_CD', response.data.resultData?.locaCd)
            
            resolve(response.data)
          } else {
            context.commit('SET_LOGIN_ERROR', response.data.resultStatus.messageText);
          }
        }).catch(error => {
          console.log('==================== error ====================')
          console.log(error)
          context.commit('SET_LOGIN_ERROR', error)
        })
      })
    },
    // 아이디 찾기
    FETCH_FIND_ID(context, credentials) {
      return new Promise(resolve => {
        fetchedFindId(credentials)
          .then(response => {
            if (response.data.resultStatus.messageCode === '2000') {

              resolve(response.data);
            } else {
              context.commit('SET_LOGIN_ERROR', response.data.resultStatus.messageText);
            }
          })
          .catch(error => {
            console.log("==================== error ====================");
            console.log(error);
            context.commit('SET_LOGIN_ERROR', error);
          });
      });

    },
    // 아이디 확인
    FETCH_CONFIRM_ID(context, credentials) {
      return new Promise(resolve => {
        fetchedConfirmId(credentials)
          .then(response => {
            if (response.data.resultStatus.messageCode === '2000') {

              resolve(response.data);
            } else {
              context.commit('SET_LOGIN_ERROR', response.data.resultStatus.messageText);
            }
          })
          .catch(error => {
            console.log("==================== error ====================");
            console.log(error);
            context.commit('SET_LOGIN_ERROR', error);
          });
      });

    },
    // 인증번호 전송
    FETCH_SEND_AUTH(context, credentials) {
      return new Promise(resolve => {
        fetchedSendAuthNumber(credentials)
          .then(response => {
            if (response.data.resultStatus.messageCode === '2000') {

              resolve(response.data);
            } else {
              context.commit('SET_LOGIN_ERROR', response.data.resultStatus.messageText);
            }
          })
          .catch(error => {
            console.log("==================== error ====================");
            console.log(error);
            context.commit('SET_LOGIN_ERROR', error);
          });
      });

    },
    /* FETCH_CONFIRM_AUTH_NUMBER(context, credentials) {
      return new Promise(resolve => {
        fetchedConfirmAuthNumber(credentials)
          .then(response => {
            if (response.data.resultStatus.messageCode === '2000') {

              resolve(response.data);
            } else {
              context.commit('SET_LOGIN_ERROR', response.data.resultStatus.messageText);
            }
          })
          .catch(error => {
            console.log("==================== error ====================");
            console.log(error);
            context.commit('SET_LOGIN_ERROR', error);
          });
      });

    }, */
    // 비밀번호 재설정
    FETCH_UPDATE_PASSWORD(context, credentials) {
      if(EtnersCommonUtil.isNotEmpty(credentials.loginUserToken)){
        return new Promise(resolve => {
          fetchedUpdatePasswordByToken(credentials)
          .then(response => {
            if (response.data.resultStatus.messageCode === '2000') {

              resolve(response.data);
            } else {
              context.commit('SET_LOGIN_ERROR', response.data.resultStatus.messageText);
            }
          })
          .catch(error => {
            console.log("==================== error ====================");
            console.log(error);
            context.commit('SET_LOGIN_ERROR', error);
          });
        });
      } else {
        return new Promise(resolve => {
          fetchedUpdatePassword(credentials)
          .then(response => {
            if (response.data.resultStatus.messageCode === '2000') {

              resolve(response.data);
            } else {
              context.commit('SET_LOGIN_ERROR', response.data.resultStatus.messageText);
            }
          })
          .catch(error => {
            console.log("==================== error ====================");
            console.log(error);
            context.commit('SET_LOGIN_ERROR', error);
          });
        });
      }

    },
    // 메뉴권한 세팅
    FETCH_MENU_DATA(context) {
      return new Promise(resolve => {
        let param = {
          cmpyCd : context.getters.loginUserData.cmpyCd,
          authCd: context.getters.loginUserData.authCd,
          unqUserId: context.getters.loginUserData.unqUserId,
          menuGbn: "01",
          wmGbn: "01",
        }
        fetchedMenuData(param)
        .then(response => {
          if(response.data.resultStatus.messageCode === '2000'){
            context.commit('SET_MENU_DATA', response.data.resultData)
            resolve(response.data);
            // sessionStorage.setItem('menuMaster', JSON.stringify(response.data.resultData))
          } else {
            resolve(response.data);
            context.commit('SET_LOGIN_ERROR', response.data.resultStatus.messageText);
          }
        })
        .catch(error => {
          console.log("==================== error ====================");
          console.log(error);
          context.commit('SET_LOGIN_ERROR', error);
        });
      });
    },
    LINK_CMPY_CHNAGE (context, cmpyCd) {
      context.commit('SET_LINK_CMPY_CD', cmpyCd)
    },
    LINK_LOCA_CHANGE (context, locaCd) {
      context.commit('SET_LINK_LOCA_CD', locaCd)
    },
    LOADING (context, isShow) {
      context.commit('SET_LOADING', isShow)
    },
    LOGOUT (context) {
      context.commit('REMOVE_LOGIN_DATA')
    },
    API_COUNT (context, cnt) {
      context.commit('SET_API_COUNT', cnt)
    },
    SET_IS_SHOW_LOGIN_POPUP (context, isShow) {
      context.commit('SET_IS_SHOW_LOGIN_POPUP', isShow)
    },
  }
};

export default loginStoreModule;
