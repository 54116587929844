import Vue from 'vue'


const ApiUtilLocal = {

  post (apiUrl, params) {

    console.log(`ApiUtilLocal > post > apiUrl ::: ${apiUrl}`)
    console.log(`ApiUtilLocal > post > params ::: ${JSON.stringify(params)}`)

    let response = this.sampleResponse(apiUrl)

    return new Promise((resolve, reject) => {
      resolve(response)
    })
  },

  sampleResponse(apiUrl) {

    if(apiUrl.indexOf('/efs/auth/login') > -1) {
      return {
        "data": {
          "resultData": {
            "firstLoginYn": "Y",
            "loginUserToken": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJFU1AgTG9naW4gVG9rZW4gdjEuMCIsImF1ZCI6ImlzbGFuZDAxMDQiLCJuYmYiOjE2NDYwMjQ1NjUsImlzcyI6ImVzcC52MS5hcGkiLCJ1YXQiOiJpaE5CQ0RVMXJZSFJjc0ZSTTBmd0R3PT0iLCJleHAiOjE2NDY0NTY2MjUsImlhdCI6MTY0NjAyNDYyNX0.lQC0DDyT-orCNUN1uR9lF-t9aUszTbkwg5obmxQ5ZwBJpaMDcUXlhqzM8fNwEEEuR0KewT9KMi6w55UfWmApdw",
            "refreshToken": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJFU1AgUmVmcmVzaCBUb2tlbiB2MS4wIiwiYXVkIjoiaXNsYW5kMDEwNCIsIm5iZiI6MTY0NjAyNDU2NSwiaXNzIjoiZXNwLnYxLmFwaSIsInVhdCI6ImloTkJDRFUxcllIUmNzRlJNMGZ3RHc9PSIsImV4cCI6MTY0NzIzNDIyNSwiaWF0IjoxNjQ2MDI0NjI1fQ.9sdVSuV7JTVQf2uQtRsXEWAPG5sIeXBuiC8xcfVPqZYx7NLPwAL5nAKuB6tqnwNqKhU6iFgUTWeUhoq9dex82g"
          },
          "resultStatus": {
            "messageCode": "2000",
            "messageDescription": "요청 처리가 성공했을 때 기본적으로 제공하는 OK 결과값.",
            "keyNamespace": "test.message.2000",
            "messageText": "정상적으로 데이터를 가져왔습니다."
          }
        }
      }
    } else if(apiUrl.indexOf('/efs/home/first-access-info') > -1) {
      return {
        "data": {
          "resultData":{
            "cmpyCd":"00001",
            "cmpyNm":"이트너스",
            "userNm":"임섬균",
            "userId":"island0104",
            "unqUserId":"2203030002",
            "authCd":"15",
            "deptNm":"PD팀",
            "wkTelNo":null,
            "spTelNo":"01083900227",
            "sabun":"221001",
            "email":"island0104@etners.com",
            "mainTask":"develpment",
            "psnScn":"01",
            "psnScnNm":"회장",
            "dutyScn":null,
            "dutyScnNm":null,
            "firstAceessInfoCmpyMng":[
            ],
            "firstAceessInfoCmpyLoca":[
            ]
          },
          "resultStatus":{
            "messageCode":"2000",
            "messageDescription":"",
            "keyNamespace":"test.message.2000",
            "messageText":"요청이 정상적으로 처리되었습니다."
          }
        }
      }
    } else if(apiUrl.indexOf('/efs/auth/find-id') > -1) {
      return {
        "data": {
          "resultData": {
            // "userId": "system"
            "userId": "island0104"
          },
          "resultStatus": {
            "messageCode": "2000",
            "messageDescription": "",
            "keyNamespace": "test.message.2000",
            "messageText": "요청이 정상적으로 처리되었습니다."
          }
        }
      }
    } else if(apiUrl.indexOf('/efs/auth/confirm-id') > -1) {
      return {
        "data": {
          "resultData": {
            "userId": "island0104",
            "spTelNo": "01083900227"
          },
          "resultStatus": {
            "messageCode": "2000",
            "messageDescription": "요청 처리가 성공했을 때 기본적으로 제공하는 OK 결과값.",
            "keyNamespace": "test.message.2000",
            "messageText": "정상적으로 데이터를 가져왔습니다."
          }
        }
      }
    } else if(apiUrl.indexOf('/efs/user/send-auth-number') > -1) {
      return {
        "data": {
          "resultData": {
            "authNo": "3231",
            "authDate": [
              2022,
              2,
              28,
              15,
              18,
              53,
              49303800
            ]
          },
          "resultStatus": {
            "messageCode": "2000",
            "messageDescription": "",
            "keyNamespace": "test.message.2000",
            "messageText": "요청이 정상적으로 처리되었습니다."
          }
        }
      }
    } else if(apiUrl.indexOf('/efs/user/user-password') > -1) {
      return {
        "data": {
          "resultData": {
            "userId": "island0104"
          },
          "resultStatus": {
            "messageCode": "2000",
            "messageDescription": "",
            "keyNamespace": "test.message.2000",
            "messageText": "요청이 정상적으로 처리되었습니다."
          }
        }
      }
    } else if(apiUrl.indexOf('/fms/rel') > -1) {
      return {
        "data": {
          "resultData": {
              "cmpyCd": "00001",
              "relCd": "0001",
              "relNm": "릴리즈1",
              "contents": null,
              "autoYn": "N",
              "useYn": "Y",
              "relGoodslResponseList": [
                  {
                    "cmpyCd": "00001",
                    "relCd": "0001",
                    "relCdNos": "1",
                    "setCd": "",
                    "setNm": "",
                    "goodsCd": "aaa",
                    "goodsNm": "상품aaa",
                    "renewGbn": "01",
                    "renewGbnNm": "신규상품",
                    "cnt": 1
                  },
                  {
                    "cmpyCd": "00001",
                    "relCd": "0001",
                    "relCdNos": "1",
                    "setCd": "000010001",
                    "setNm": "세트테스트b",
                    "goodsCd": "setbbb",
                    "goodsNm": "상품bbb",
                    "renewGbn": "01",
                    "renewGbnNm": "신규상품",
                    "cnt": 1
                },
                {
                    "cmpyCd": "00001",
                    "relCd": "0001",
                    "relCdNos": "1",
                    "setCd": "000010002",
                    "setNm": "세트테스트c",
                    "goodsCd": "setccc",
                    "goodsNm": "상품ccc",
                    "renewGbn": "01",
                    "renewGbnNm": "신규상품",
                    "cnt": 1
                },
                {
                    "cmpyCd": "00001",
                    "relCd": "0001",
                    "relCdNos": "1",
                    "setCd": "000010003",
                    "setNm": "세트테스트d",
                    "goodsCd": "setddd",
                    "goodsNm": "상품ddd",
                    "renewGbn": "01",
                    "renewGbnNm": "신규상품",
                    "cnt": 1
                },
                {
                    "cmpyCd": "00001",
                    "relCd": "0001",
                    "relCdNos": "1",
                    "setCd": "",
                    "setNm": "",
                    "goodsCd": "eee",
                    "goodsNm": "상품eee",
                    "renewGbn": "01",
                    "renewGbnNm": "신규상품",
                    "cnt": 1
                },
              ]
          },
          "resultStatus": {
              "messageCode": "2000",
              "messageDescription": "",
              "keyNamespace": "test.message.2000",
              "messageText": "요청이 정상적으로 처리되었습니다."
          }
        }
      }
    }
  }

}

export default ApiUtilLocal
