import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'

const efsDomain = ApiConfig.efsDomain

const codeRequestStoreModule = {
    state: {
        dataSource: {
            lastRequestParameters: {},
        },
        search: {
            button: {
                useYn: { // 사용여부 (예/아니오)
                    type: 'radio',
                    data: [],
                    index: 0,
                },
            },
            input: {

            },
            datepicker: {

            },
            combobox: {
                // 001 구분
                cmpyGbn: {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 007 공정 물류구분 Depth1
                logiDepth1 : {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 012 공지구분
                boardGbn: {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 014 상품구분
                renewGbn: {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                psnScn: {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 015 개별상품상태
                brcStatus: {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 016 재고상태
                stkStatus : {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 017	신품입고 등록구분
                newInGbn: {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 019 세트구분
                setGbn : {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 020 상품이동수단
                mvType : {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 021	입출고운송수단
                inoutType : {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 022 입출고구분
                inGbn: {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                outGbn: {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 023 입고상태
                inStatus: {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 024 출고상태
                outStatus: {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 025 회수사유
                inReqRsn: {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 026	배송상태
                delvStatus: {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 028	퀵 운송수단
                qckVehicleCd: {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 029	퀵 픽업구분
                qckPuType: {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 031	퀵 배송지유형
                qckDelvType: {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 033	퀵 물품종류
                qckGoodsType: {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 038  문의타입
                contactType: {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 040  서비스등급
                svcGrade: {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 042  물류운영형태
                logiType : {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 043	월 예상 출고건수
                expectMonthOutCnt: {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 044	승인상태
                apprvStatus: {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 048	신규회사 등록시 생성될 기본 로케이션 주소 구성
                defaultLocaInfo: {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
                // 049	입출고 지정 구분
                inoutDsgnGbn: {
                    type: 'combobox',
                    data: [],
                    index: 0,
                },
            },
        },
        currentRow: {
            currentRowData: [], // kendo grid에서 행위가 일어나는 row의 data
        },
    },
    getters: {
        newInGbnDataForRadioGrup(state) {
            let newInGbnData = []
            state.search.combobox.newInGbn.data.forEach(item => {
                newInGbnData.push({
                    label: item.cdNm,
                    value: item.cdId
                })
            })
            return newInGbnData
        }
    },
    mutations: {
        setUseYnData(state, data) {
            state.search.button.useYn.data = data
        },
        setUseYnIndex(state, index) {
            state.search.button.useYn.index = index
        },
        setCmpyGbnData(state, data) {
            state.search.combobox.cmpyGbn.data = data
        },
        setCmpyGbnIndex(state, index) {
            state.search.combobox.cmpyGbn.index = index
        },
        setLogiDepth1Data(state, data) {
            state.search.combobox.logiDepth1.data = data
        },
        setLogiDepth1Index(state, index) {
            state.search.combobox.logiDepth1.index = index
        },
        setBoardGbnData(state, data) {
            state.search.combobox.boardGbn.data = data
        },
        setRenewGbnData(state, data) {
            state.search.combobox.renewGbn.data = data
        },
        setRenewGbnIndex(state, index) {
            state.search.combobox.renewGbn.index = index
        },
        setPsnScnData(state, data) {
            state.search.combobox.psnScn.data = data
        },
        setPsnScnIndex(state, index) {
            state.search.combobox.psnScn.index = index
        },
        setBrcStatusData(state, data) {
            state.search.combobox.brcStatus.data = data
        },
        setStkStatusData(state, data) {
            state.search.combobox.stkStatus.data = data
        },
        setNewInGbnData(state, data) {
            state.search.combobox.newInGbn.data = data
        },
        setInGbnData(state, data) {
            state.search.combobox.inGbn.data = data
        },
        setOutGbnData(state, data) {
            state.search.combobox.outGbn.data = data
        },
        inStatusData(state, data) {
            state.search.combobox.inStatus.data = data
        },
        outStatusData(state, data) {
            state.search.combobox.outStatus.data = data
        },
        setInReqRsnData(state, data) {
            state.search.combobox.inReqRsn.data = data
        },
        setDelvStatusData(state, data) {
            state.search.combobox.delvStatus.data = data
        },
        setSetGbnData(state, data) {
            state.search.combobox.setGbn.data = data
        },
        setMvTypeData(state, data) {
            state.search.combobox.mvType.data = data
        },
        setInoutTypeData(state, data) {
            state.search.combobox.inoutType.data = data
        },
        setQckVehicleCdData(state, data) {
            state.search.combobox.qckVehicleCd.data = data
        },
        setQckPuTypeData(state, data) {
            state.search.combobox.qckPuType.data = data
        },
        setQckDelvTypeData(state, data) {
            state.search.combobox.qckDelvType.data = data
        },
        setQckGoodsTypeData(state, data) {
            state.search.combobox.qckGoodsType.data = data
        },
        setContactType(state, data) {
            state.search.combobox.contactType.data = data
        },
        setSvcGradeData(state, data) {
            state.search.combobox.svcGrade.data = data
        },
        setLogiTypeData(state, data) {
            state.search.combobox.logiType.data = data
        },
        setExpectMonthOutCntData(state, data) {
            state.search.combobox.expectMonthOutCnt.data = data
        },
        setApprvStatusData(state, data) {
            state.search.combobox.apprvStatus.data = data
        },
        setDefaultLocaInfoData(state, data) {
            state.search.combobox.defaultLocaInfo.data = data
        },
        setInoutDsgnGbnData(state, data) {
            state.search.combobox.inoutDsgnGbn.data = data
        },
    },
    actions: {

        /**
         * SRM 솔루션의 코드 데이터 map 조회
         * @param context
         */
        initSearchDataForCodeRequest(context) {


            /** 여러개의 공통 코드를 동시에 요청할 경우에 대한 파라미터값 */
            const url = `${efsDomain}/efs/module/code/multi-codes`
            let codes = []
            let cdGrupString = ''
            let passNumberArr = []
            for (let i = 1; i <= 49; i++) {

                if (!passNumberArr.includes(i)) {
                    cdGrupString = EtnersCommonUtil.lpadString('0', 3, i)
                    let obj = {
                        cdGrup: cdGrupString,
                        options: {
                            displayAll: false,
                        },
                    }
                    codes.push(obj)
                }
            }

            for (let i = 217; i <= 217; i++) {
                cdGrupString = EtnersCommonUtil.lpadString('0', 3, i)
                let obj = {
                    cdGrup: cdGrupString,
                    options: {
                        displayAll: false,
                    },
                }
                codes.push(obj)
            }

            let param = { codes }

            ApiUtil.post(url, param).then(response => {
                let codeMap = response.data.codeMap

                // 001 회사구분
                context.commit('setCmpyGbnData', codeMap['001'])

                // 005	직위
                let psnScnDataArray = codeMap['005']
                psnScnDataArray = EtnersCommonUtil.sortArray(psnScnDataArray, 'seqNo')
                context.commit('setPsnScnData', psnScnDataArray)
                for (let i = 0; i < psnScnDataArray.length; i++) {
                    const code = psnScnDataArray[i]
                    if (code.selected === true) {
                        context.commit('setPsnScnIndex', i)
                    }
                }

                context.commit('setLogiDepth1Data', codeMap['007'])

                // 012      공지구분
                context.commit('setBoardGbnData', codeMap['012'])

                // 014      상품구분
                let renewGbnDataArray = codeMap['014']
                renewGbnDataArray = EtnersCommonUtil.sortArray(renewGbnDataArray, 'seqNo')
                context.commit('setRenewGbnData', renewGbnDataArray)
                for (let i = 0; i < renewGbnDataArray.length; i++) {
                    const code = renewGbnDataArray[i]
                    if (code.selected === true) {
                        context.commit('setRenewGbnIndex', i)
                    }
                }

                // 015      상품구분
                context.commit('setBrcStatusData', codeMap['015'])
                
                // 016      재고상태
                context.commit('setStkStatusData', codeMap['016'])

                // 017      신품입고 등록구분
                let newInGbnDataArray = codeMap['017']
                newInGbnDataArray = EtnersCommonUtil.sortArray(newInGbnDataArray, 'seqNo')
                context.commit('setNewInGbnData', newInGbnDataArray)

                // 019      세트구분
                context.commit('setSetGbnData', codeMap['019'])

                // 020      상품이동운송수단
                context.commit('setMvTypeData', codeMap['020'])

                // 021	    입출고운송수단
                let inoutTypeDataArray = codeMap['021']
                inoutTypeDataArray = EtnersCommonUtil.sortArray(inoutTypeDataArray, 'seqNo')
                context.commit('setInoutTypeData', inoutTypeDataArray)

                // 022      입출고구분
                let inGbnData = codeMap['022'].filter((data)=>{ return !['01','02','08'].includes(data.cdId)})
                context.commit('setInGbnData', inGbnData)
                context.commit('setOutGbnData', codeMap['022'])

                // 023      입고상태
                context.commit('inStatusData', codeMap['023'])

                // 024      출고상태
                context.commit('outStatusData', codeMap['024'])

                // 025      회수사유
                context.commit('setInReqRsnData', codeMap['025'])

                // 026      배송상태
                context.commit('setDelvStatusData', codeMap['026'])

                // 028      퀵 운송수단
                let qckVehicleCdDataArray = codeMap['028']
                qckVehicleCdDataArray = EtnersCommonUtil.sortArray(qckVehicleCdDataArray, 'seqNo')
                context.commit('setQckVehicleCdData', qckVehicleCdDataArray)

                // 029      퀵 픽업구분
                let qckPuTypeDataArray = codeMap['029']
                qckPuTypeDataArray = EtnersCommonUtil.sortArray(qckPuTypeDataArray, 'seqNo')
                context.commit('setQckPuTypeData', qckPuTypeDataArray)

                // 031      퀵 배송지유형
                let qckDelvTypeDataArray = codeMap['031']
                qckDelvTypeDataArray = EtnersCommonUtil.sortArray(qckDelvTypeDataArray, 'seqNo')
                context.commit('setQckDelvTypeData', qckDelvTypeDataArray)

                // 033	    퀵 물품종류
                let qckGoodsTypeDataArray = codeMap['033']
                qckGoodsTypeDataArray = EtnersCommonUtil.sortArray(qckGoodsTypeDataArray, 'seqNo')
                context.commit('setQckGoodsTypeData', qckGoodsTypeDataArray)

                // 038      문의 유형
                context.commit('setContactType', codeMap['038'])

                // 040      서비스 등급
                context.commit('setSvcGradeData', codeMap['040'])

                // 042      물류 운영 형태
                context.commit('setLogiTypeData', codeMap['042'])

                // 043      월 예상 출고건수
                context.commit('setExpectMonthOutCntData', codeMap['043'])

                // 044      승인상태
                context.commit('setApprvStatusData', codeMap['044'])

                // 048
                context.commit('setDefaultLocaInfoData', codeMap['048'])
                
                // 049      입출고 지정구분
                context.commit('setInoutDsgnGbnData', codeMap['049'])

                

                let useYnDataArray = [{
                    cdNm: '전체',
                    cdId: '',
                    cdNameSpace: '',
                    selected: true,
                },
                    {
                        cdNm: '예',
                        cdId: 'Y',
                        cdNameSpace: 'Y',
                        selected: false,
                    },
                    {
                        cdNm: '아니오',
                        cdId: 'N',
                        cdNameSpace: 'N',
                        selected: false,
                    },
                ]

                context.commit('setUseYnData', useYnDataArray)
                for (let i = 0; i < useYnDataArray.length; i++) {
                    const code = useYnDataArray[i]
                    if (code.selected === true) {
                        context.commit('setUseYnIndex', i)
                    }
                }
            })
        },
    },
}

export default codeRequestStoreModule
