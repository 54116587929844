<template>
  <div id="app">
    <vue-extend-layouts path="layouts" layout="MainLayout"/>
  </div>
</template>

<script>
/* Layout Libraries 설정
*  default 이름 변경 시 prop layout에 변경된 이름을 명시해줘야함
* */
import VueExtendLayouts from 'vue-extend-layout'
export default {
  name: 'App',
  components: { VueExtendLayouts }
}
</script>
