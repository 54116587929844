import Vue from 'vue'
import Vuex from 'vuex'
import loginStoreModule from './store.login'
// import userStoreModule from './store.user'
// import urlStoreModule from './store.url'
import codeRequestStoreModule from './store.code.request'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    loginStore : loginStoreModule,
    // userStore: userStoreModule,
    // urlStore: urlStoreModule,
    codeRequestStore: codeRequestStoreModule
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: ['loginStore', 'codeRequestStore'],
    }),
  ]
})
