//개발 테스트시 API쪽 URL을 변경해야할 때 여기 값을 변경해주세요.
//test
const defaultEfsDomain = location.hostname.includes('dev') || location.hostname.includes('localhost') ? 'https://dev-efs-api.etnersefs.com' : 'https://efs-api.etnersefs.com'
//real
// const defaultEfsDomain = location.hostname.includes('dev') || location.hostname.includes('localhost') ? 'https://dev-efs-api.etnersefs.com' : 'https://efs-api.etnersefs.com'
const defaultFmsDomain = location.hostname.includes('dev') || location.hostname.includes('localhost') ? 'https://dev-fms-api.etnersefs.com' : 'https://fms-api.etnersefs.com'
//이트너스 홈페이지 히스토리 관리를 위해 추가(랜딩페이지)
const defaultHomeDomain = location.hostname.includes('dev') || location.hostname.includes('localhost') ? 'https://dev-etnershome-api.etners.com' : 'https://etnershome-api.etners.com'

const ApiConfig = {
  efsDomain: sessionStorage.getItem('efsDomain') == null ? defaultEfsDomain : sessionStorage.getItem('efsDomain'),
  fmsDomain: sessionStorage.getItem('fmsDomain') == null ? defaultFmsDomain : sessionStorage.getItem('fmsDomain'),
  homeDomain: sessionStorage.getItem('homeDomain') == null ? defaultHomeDomain : sessionStorage.getItem('homeDomain'),
  mode: process.env.NODE_ENV
}

//개발 모드에서 로컬 URL 및 포트로 변경하고자 할 때 사용
if(process.env.NODE_ENV !== 'production') {
  // ApiConfig.efsDomain = 'http://localhost:8052'
  // ApiConfig.fmsDomain = 'http://localhost:8052'
}



export default ApiConfig
