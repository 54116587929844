import ApiConfig from './api.config'
import ApiUtil from './api.util'


// 로그인
function fetchedLoginData(params) {

  return  ApiUtil.post(`${ApiConfig.efsDomain}/efs/auth/login`, params);
}
// 로그인 사용자 정보
function fetchedLoginUserData(params) {

  return ApiUtil.post(`${ApiConfig.efsDomain}/efs/home/first-access-info`, params)
}
// 아이디 찾기
function fetchedFindId(params) {

  return ApiUtil.post(`${ApiConfig.efsDomain}/efs/auth/find-id`, params);
}

// 아이디 확인
function fetchedConfirmId(params){

  return ApiUtil.post(`${ApiConfig.efsDomain}/efs/auth/confirm-id`, params)
}

// 인증번호 전송
function fetchedSendAuthNumber(params){

  return ApiUtil.post(`${ApiConfig.efsDomain}/efs/user/send-auth-number`, params)
}

/* function fetchedConfirmAuthNumber(params){
  return ApiUtil.post(`${ApiConfig.fmsDomain}/ebmp/main/user/confirmAuthNumber.json` , params)
} */

// 비밀번호 재설정
function fetchedUpdatePassword(params){

  return ApiUtil.post(`${ApiConfig.efsDomain}/efs/user/user-password`, params)
}

// 비밀번호 재설정(토큰)
function fetchedUpdatePasswordByToken(params){
  return ApiUtil.put(`${ApiConfig.efsDomain}/efs/user/user-password-by-token`, params)
}

// 메뉴권한 데이터
function fetchedMenuData(params){
  return ApiUtil.query(`${ApiConfig.efsDomain}/efs/menu`, params)
}


export {
  fetchedLoginData,
  fetchedLoginUserData,
  fetchedFindId,
  fetchedConfirmId,
  fetchedSendAuthNumber,
  // fetchedConfirmAuthNumber,
  fetchedUpdatePassword,
  fetchedUpdatePasswordByToken,
  fetchedMenuData
}
